export const GET_PRODUCERS = 'producers/GET_PRODUCERS';
export const GET_PRODUCERS_SUCCESS = 'producers/GET_PRODUCERS_SUCCESS';
export const GET_PRODUCERS_FAILED = 'producers/GET_PRODUCERS_FAILED';
export const CREATE_PRODUCER = 'producers/CREATE_PRODUCER';
export const CREATE_PRODUCER_SUCCESS = 'producers/CREATE_PRODUCER_SUCCESS';
export const CREATE_PRODUCER_FAILED = 'producers/CREATE_PRODUCER_FAILED';
export const ARCHIVE_PRODUCER = 'producers/ARCHIVE_PRODUCER';
export const ARCHIVE_PRODUCER_SUCCESS = 'producers/ARCHIVE_PRODUCER_SUCCESS';
export const ARCHIVE_PRODUCER_FAILED = 'producers/ARCHIVE_PRODUCER_FAILED';
export const GET_PRODUCER_BY_ID = 'producers/GET_PRODUCER_BY_ID';
export const GET_PRODUCER_BY_ID_SUCCESS = 'producers/GET_PRODUCER_BY_ID_SUCCESS';
export const GET_PRODUCER_BY_ID_FAILED = 'producers/GET_PRODUCER_BY_ID_FAILED';
export const EDIT_PRODUCER = 'producers/EDIT_PRODUCER';
export const EDIT_PRODUCER_SUCCESS = 'producers/EDIT_PRODUCER_SUCCESS';
export const EDIT_PRODUCER_FAILED = 'producers/EDIT_PRODUCER_FAILED';