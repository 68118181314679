export const GET_BEANS = 'beans/GET_BEANS';
export const GET_BEANS_SUCCESS = 'beans/GET_BEANS_SUCCESS';
export const GET_BEANS_FAILED = 'beans/GET_BEANS_FAILED';
export const ARCHIVE_BEAN = 'beans/ARCHIVE_BEAN';
export const ARCHIVE_BEAN_SUCCESS = 'beans/ARCHIVE_BEAN_SUCCESS';
export const ARCHIVE_BEAN_FAILED = 'beans/ARCHIVE_BEAN_FAILED';
export const GET_BEAN_BY_ID = 'beans/GET_BEAN_BY_ID';
export const GET_BEAN_BY_ID_SUCCESS = 'beans/GET_BEAN_BY_ID_SUCCESS';
export const GET_BEAN_BY_ID_FAILED = 'beans/GET_BEAN_BY_ID_FAILED';
export const EDIT_BEAN = 'beans/EDIT_BEAN';
export const EDIT_BEAN_SUCCESS = 'beans/EDIT_BEAN_SUCCESS';
export const EDIT_BEAN_FAILED = 'beans/EDIT_BEAN_FAILED';
export const CREATE_BEAN = 'beans/CREATE_BEAN';
export const CREATE_BEAN_SUCCESS = 'beans/CREATE_BEAN_SUCCESS';
export const CREATE_BEAN_FAILED = 'beans/CREATE_BEAN_FAILED';
export const PURCHASE_BEAN = 'beans/PURCHASE_BEAN';
export const PURCHASE_BEAN_SUCCESS = 'beans/PURCHASE_BEAN_SUCCESS';
export const PURCHASE_BEAN_FAILED = 'beans/PURCHASE_BEAN_FAILED';