import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AppStore, UserInfo } from 'models';
import { signInByToken, signInBwApiCognito, getCognitoConfig} from 'redux/actions';
import App from 'containers/App';
import LoginPage from 'containers/LoginPage';
import LoginCognitoPage from 'containers/LoginCognitoPage';
import ForgotContainer from "containers/LoginPage/ForgotContainer";
import ResetContainer from "containers/LoginPage/ResetContainer";
import PrivateRoute from 'components/PrivateRoute';
import { CognitoAuth } from 'amazon-cognito-auth-js';

interface Props {
  isAuthenticated: boolean;
  isLoading: boolean;
  userInfo: UserInfo | null;
  refreshToken: () => void;
};

const AppLoader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return <App />
  }
  return <p>Loading...</p>
}

class MainRouter extends React.Component<Props> {

  private auth: CognitoAuth;

  constructor(props){
    super(props);
    
  }

  componentDidMount() {
    if (this.props.isAuthenticated && !this.props.userInfo) {
      //this.props.refreshToken();
    }
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route component={ForgotContainer} path="/forgot" />
          <Route component={ResetContainer} path="/reset" />
          <PrivateRoute component={() => <AppLoader isLoading={!this.props.userInfo} />} path="/lots" />
          <PrivateRoute component={() => <AppLoader isLoading={!this.props.userInfo} />} path="/producers" />
          <PrivateRoute component={() => <AppLoader isLoading={!this.props.userInfo} />} path="/organizations" />
          <PrivateRoute component={() => <AppLoader isLoading={!this.props.userInfo} />} path="/settings" />
          <Route component={LoginCognitoPage} path="/" />
        </Switch>
      </Router>
    );
  }
}
const mapStateToProps = (state: AppStore) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  userInfo: state.auth.userInfo,
  cognitoConfig: state.auth.cognitoConfig
});
const mapDispatchToProps = dispatch => ({
  refreshToken: () => dispatch(signInByToken()),
  signInBwApiCognito: (tokens) => dispatch(signInBwApiCognito(tokens)),
  getCognitoConfig: () => dispatch(getCognitoConfig())
});
export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);
