
import { CognitoAuth } from 'amazon-cognito-auth-js';

class CognitoAuthMod {
    private auth: CognitoAuth;

    initCognitoSDK(authData, callback){
        this.auth = new CognitoAuth(authData);

        this.auth.userhandler = {
            onSuccess: (result) => {
              callback(result);
            },
            onFailure: function(err) {
                console.log(err);
            }
        };

        this.auth.useCodeGrantFlow();
    }

    parseCognitoWebResponse(currentUrl){
        this.auth.parseCognitoWebResponse(currentUrl);
    }

    getSession() {
        this.auth.getSession();
    }

    signOut(){
        this.auth.signOut({ global: true });
        this.auth.signOut();
    }
}

export default new CognitoAuthMod();