export const GET_USERS_ORGANIZATION = 'users/GET_USERS_ORGANIZATION';
export const GET_USERS_ORGANIZATION_SUCCESS = 'users/GET_USERS_ORGANIZATION_SUCCESS';
export const GET_USERS_ORGANIZATION_FAILED = 'users/GET_USERS_ORGANIZATION_FAILED';
export const CREATE_USER = 'users/CREATE_USER';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'users/CREATE_USER_FAILED';
export const EDIT_USER = 'users/EDIT_USER';
export const EDIT_USER_SUCCESS = 'users/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'users/EDIT_USER_FAILED';
export const GET_USER = 'users/GET_USERS_ORGANIZATION';
export const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS';
export const GET_USER_FAILED = 'users/GET_USER_FAILED';