import { BW } from 'api';
import * as actions from '../constants';
import { UserInfo, AppStore } from 'models';
import cognitoAuthMod from "../../constants/cognitoAuth";

export const signInByToken = () => (dispatch, getState: () => AppStore) => {
	dispatch({ type: actions.REFRESH_TOKEN });
	const token = getState().auth.token;
	return BW.post<UserInfo>('/auth/signInByToken', { token })
		.then(userInfo => dispatch({ type: actions.REFRESH_TOKEN_SUCCESS, data: { payload: { token, userInfo } } }))
		.catch(err => dispatch({ type: actions.REFRESH_TOKEN_FAILED }));
};

export const getCognitoConfig = () => (dispatch) => {
	BW.get(`/auth/cognitoConfig?app=admin_api&user_pool=customers`)
	.then((cognitoConfig: any) => {
		BW.cognito_client_id = cognitoConfig.ClientId;
		BW.cognito_domain = cognitoConfig.AppWebDomain;
		return dispatch({
			type: actions.GET_COGNITO_CONFIG_SUCCESS,
			data: {
				cognitoConfig
			}
	})}).catch(err => dispatch({ type: actions.GET_COGNITO_CONFIG_FAILED}))
};

export const signInBwApiCognito = (tokens) => (dispatch) => {
	let idToken = tokens.idToken.jwtToken;
    let accToken = tokens.accessToken.jwtToken;
	let refToken = tokens.refreshToken.refreshToken;
	BW.cognito_refresh_token = refToken;
	return BW.post<{ token: string; userInfo: UserInfo }>('/auth/signInCognito', {idToken, accToken, refToken})
		.then(payload => {
			dispatch({ type: actions.SIGN_IN_BWAPI_COGNITO_SUCCESS, data: { payload: { userInfo: payload, token: accToken } } });
		}).catch(error => {
			console.log("Error", error);
			dispatch({ type: actions.SIGN_IN_BWAPI_COGNITO_FAILED, data: { error } });
		});
};

export const signIn = (email: string, password: string) => dispatch => {
	dispatch({ type: actions.GET_AUTH_TOKEN });
	return BW.post<{ token: string; userInfo: UserInfo }>('/auth/signIn', { email, password })
		.then(payload => {
			dispatch({ type: actions.GET_AUTH_TOKEN_SUCCESS, data: { payload } });
		})
		.catch(error => {
			dispatch({ type: actions.GET_AUTH_TOKEN_FAILED, data: { error } });
		})
};

export const signOut = () => dispatch => {
	BW.setHeaders('Authorization', '');
	cognitoAuthMod.signOut();
	dispatch({ type: actions.DESTROY_AUTH_TOKEN });
};

export const forgetPassword = (email: string, password: string) => dispatch => {
	dispatch({ type: actions.FORGET_PASSWORD });
	return BW.post<any>('/v2/auth/forget-password', { email, password })
		.then(forget => {
			dispatch({ type: actions.FORGET_PASSWORD_SUCCESS, data: { forget } });
		})
		.catch(error => {
			dispatch({ type: actions.FORGET_PASSWORD_FAILED, data: { error } });
		})
};

export const sendForgetPasswordEmail = (email: string, domain: string) => dispatch => {
	dispatch({ type: actions.FORGET_PASSWORD_EMAIL });
	return BW.post<any>('/v2/auth/send-forget-email', { email, domain })
		.then(send => {
			dispatch({ type: actions.FORGET_PASSWORD_EMAIL_SUCCESS, data: { send } });
		})
		.catch(error => {
			dispatch({ type: actions.FORGET_PASSWORD_EMAIL_FAILED, data: { error } });
		})
};