import React, { useEffect, useState } from "react";
import _ from "lodash";
import { AppStore, UserInfo, BaseSingleOrganization } from "models";
import { getSingleOrganizations, updateOrganization } from "redux/actions/organizations";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Heading, Title } from "styledComponents/globals";
import { ToggleStateless } from "@atlaskit/toggle";
import { FormFooter } from "@atlaskit/form";
import Button, { ButtonGroup } from "@atlaskit/button";
import Message from "components/Message";
import Styles from "./Styles";

export interface SettingsContainerProps extends RouteComponentProps {
	isLoading: boolean;
	userInfo: UserInfo;
	cafe: BaseSingleOrganization;
	getSingleOrganizations: (cafeId: string) => void;
	updateOrganization: (cafeId: string, updateData: {}, callback) => void;
}

const initialToast = {
	text: "",
	type: "",
	show: false,
};

const SettingsContainer: React.FunctionComponent<SettingsContainerProps> = (props) => {
	const [toast, setToast] = useState(initialToast);
	const [showToggle, setShowToggle] = useState(false);
	const [checked, setChecked] = useState(false);
	const [loadingSaving, setLoadingSaving] = useState(false);
	const { getSingleOrganizations, cafe } = props;

	useEffect(() => {
		getSingleOrganizations(props.userInfo.cafes[0]);
	}, []);

	useEffect(() => {
		if (cafe) {
			cafe.canHideDiagnostics ? setShowToggle(true) : setShowToggle(false);
			cafe.hideDiagnostics ? setChecked(false) : setChecked(true);
		}
	}, [cafe]);

	const handleChange = () => setChecked(!checked);

	const submissionCallback = (text, type) => {
		const toastClone = _.cloneDeep(toast);
		toastClone.text = text;
		toastClone.type = type;
		toastClone.show = true;

		setToast(toastClone);
		setLoadingSaving(false);
	};

	const handleSubmit = async () => {
		setLoadingSaving(true);
		const cafeClone = _.cloneDeep(props.cafe);
		cafeClone.hideDiagnostics = !checked;
		await props.updateOrganization(props.userInfo.cafes[0], cafeClone, submissionCallback);
	};

	return (
		<>
			<Message show={toast.show} text={toast.text} type={toast.type} />
			{showToggle && (
				<div style={Styles.Container}>
					<Heading>
						<Title>Settings</Title>
					</Heading>
					<div style={Styles.SectionContainer}>
						<h3 style={Styles.SectionTitle}> Diagnostic Data Privacy </h3>
						<p style={Styles.Content}>
							If you would like to continue sharing roast diagnostic data with Bellwether to detect
							issues with your roaster please keep the switch turned on. To stop sharing and hide
							diagnostic data, turn the switch off.
						</p>
						<p style={Styles.Content}>
							Note: if you switch this feature off Bellwether will not be able to monitor issues
							with your roasting live. To run diagnostics when roasting is not properly
							running, Bellwether will need to send a diagnostic profile to monitor which may delay
							the process of diagnosing failures in the roaster.
						</p>
					</div>
					<div style={Styles.SectionContainerFlex}>
						<h3 style={Styles.SectionTitle}> Share my roasting diagnostic data </h3>
						<ToggleStateless
							isChecked={checked}
							onChange={() => handleChange()}
							label="Share roast profile toggle"
							name="shareToggle"
							size="large"
						/>
					</div>
					<div style={Styles.buttonsContainer}>
						<div style={{ width: 221 }}>
							<Button style={Styles.buttonCancel}>Cancel</Button>
						</div>
						<div style={{ width: 221 }}>
							<Button
								appearance="primary"
								style={
									loadingSaving || !showToggle ? Styles.buttonPublishLoading : Styles.buttonPublish
								}
								onClick={() => handleSubmit()}
								isDisabled={loadingSaving || !showToggle}>
								Save
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state: AppStore) => ({
	userInfo: state.auth.userInfo,
	isLoading: state.organizations.isLoading,
	cafe: state.organizations.cafe,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleOrganizations: (cafeId) => dispatch(getSingleOrganizations(cafeId)),
	updateOrganization: (cafeId, updateData, callback) =>
		dispatch(updateOrganization(cafeId, updateData, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
