import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { Dictionary } from 'lodash';

class Api {
  signOut: Function;
  axiosInstance: AxiosInstance;
  baseUrl: string;
  headers: Dictionary<string> = {
    'Content-Type': 'application/json',
  };
  cognito_refresh_token: string;
  cognito_domain: string;
  cognito_client_id: string;

  constructor(url: string) {
    this.baseUrl = url;
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 90000,
      headers: this.headers,
    });
  }

  setUrl = (url: string) => {
    this.baseUrl = url;
    this.axiosInstance.defaults.baseURL = url;
  }

  setHeaders = (key: string, value: string) => {
    this.axiosInstance.defaults.headers[key] = value;
    return this;
  }

  requestNewAccessToken (path, callback) {
    var http = new XMLHttpRequest();
    var url = "https://" + this.cognito_domain + "/oauth2/token";
    var params = `grant_type=refresh_token&client_id=${this.cognito_client_id}&refresh_token=${this.cognito_refresh_token}`;
    http.open('POST', url, true);

    //Send the proper header information along with the request
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    http.onreadystatechange = () => {//Call a function when the state changes.
        if(http.readyState == 4 && http.status == 200) {
            console.log(JSON.parse(http.responseText)["access_token"]);
            this.setHeaders('Authorization',JSON.parse(http.responseText)["access_token"]);
            callback(0);
        } else {
            this.signOut();
            callback("Refresh Token expired");
        }
    }
    http.send(params);

}

  get<T>(path: string, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.get<T>(path, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => {
          if(error.response.data.error === "Cannot decode accessToken") {
            this.requestNewAccessToken(path, (error) => {
              if(error === 0){
                this.axiosInstance.get<T>(path, opts).then(response =>{
                  resolve(response.data);
                });
              } else {
                reject(error);
              }
              
            });
            
          } else {
            reject(error.response)
          }
        });
    })
  }

  post<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => {
          if(error.response.data.error === "Cannot decode accessToken") {
            this.requestNewAccessToken(path, (error) => {
              if(error === 0){
                this.axiosInstance.post<T>(path, opts).then(response =>{
                  resolve(response.data);
                });
              } else {
                reject(error);
              }
              
            });
            
          } else {
            reject(error.response)
          }
        });
    })
  }
  put<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.put<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })
  }

  patch<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.patch<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })

  }
  delete(path: string, opts?: AxiosRequestConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.delete(path, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    });
  }
}
export const BW = new Api(process.env.REACT_APP_BW_API_URL);
