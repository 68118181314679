import { CSSProperties } from 'react';

export const styles = {
  Container: {
    width: '100%'
  }as CSSProperties,

  SectionContainer: {
    marginTop: 31,
    width: '95%'
  }as CSSProperties,

  SectionContainerFlex: {
    display: 'flex',
    marginTop: 31,
    width: '95%'
  } as CSSProperties,

  SectionTitle: {
    fontSize: 21,
    fontWeight: 'bold',
    marginRight: 20,
    color: '#3B3B3B',
  } as CSSProperties,

  Content: {
    marginTop: 25,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.7,
    color: '#3B3B3B',
  } as CSSProperties,

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'calc(100vw - 296px)',
    minWidth: 700,
    height: 73,
    background: '#F4F5F9',
    position: 'fixed',
    bottom: 0,
    paddingLeft: 27,
    paddingRight: 27,
    boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.19)',
    marginLeft: -64,
    zIndex: 400
  } as CSSProperties,

  buttonCancel: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 40,
    background: '#F4F5F9',
    border: '1px solid rgba(59,59,59,0.5)',
    fontSize: 16,
    marginTop: 21
  } as CSSProperties,


  buttonPublish: {
    display: 'flex', 
    justifyContent: 'center', 
    width: '100%',
    background: '#7876E0',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 21
  } as CSSProperties,

  buttonPublishLoading:{
    display: 'flex', 
    justifyContent: 'center', 
    width: '100%',
    backgroundColor: 'rgba(120, 118, 224, 0.4)',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 21,
  } as CSSProperties
}

export default styles;